<template>
    <div>
        <div v-if="status && status.access_data">
            已授权Workspace: {{status.access_data.workspace_name}}
        </div>
        <div v-else>
            <v-btn @click="startConnect" class="primary mt-3">开始连接</v-btn>
        </div>
    </div>
</template>

<script>
import MixinConnectBase from "@/mixins/connectBase";

export default {
    mixins: [MixinConnectBase],
    methods: {
        startConnect () {
            this.mixStartConnect().then(res=>{
                window.open(res.code_url, '_blank')
                this.$fs.modal({
                    title: '已完成连接',
                    text: '请完成连接后重新进入，以刷新连接结果。',
                    confirm: ()=>{
                        this.$router.go(-1)
                    },
                    hideCancel: true,
                })
            }).catch(err=>{
                this.$fs.toast(err)
            })
        }
    }
}
</script>